import React, { Component } from 'react';
import PropTypes from "prop-types";
import { ensure_media_stream } from '../../../utils/AudioUtils';

class AudioWaveform extends Component {
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.updateCanvasDimensions = this.updateCanvasDimensions.bind(this);
    }

    componentDidMount() {
        this.updateCanvasDimensions();
        window.addEventListener('resize', this.updateCanvasDimensions);
    }

    componentDidUpdate() {
        this.draw();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCanvasDimensions);
    }

    updateCanvasDimensions() {
        const canvas = this.canvas.current;
        if (canvas) {
            canvas.width = canvas.parentElement.clientWidth; // Adjust to fit parent container
            canvas.height = Math.max(125, canvas.parentElement.clientHeight / 2); // Example height adjustment
            this.draw(); // Redraw the waveform after resizing
        }
    }

    draw() {
        const { audioData } = this.props;
        const canvas = this.canvas.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        const height = canvas.height;
        const width = canvas.width;

        context.imageSmoothingEnabled = false;
        context.clearRect(0, 0, width, height);

        const sliceWidth = width / audioData.length;
        let x = 0;

        context.lineWidth = 1;
        context.strokeStyle = '#5774f0';
        context.beginPath();
        context.moveTo(0, height / 2);

        for (const item of audioData) {
            const y = (item / 255.0) * height;
            context.lineTo(x, y);
            x += sliceWidth;
        }

        context.lineTo(width, height / 2);
        context.stroke();
    }

    render() {
        const canvasStyle = {
            width: '100%', // Ensure the canvas scales to the full width of its container
            height: 'auto' // Allow proportional scaling
        };

        return (
            <div style={{ width: '96%', height: '100%', position: 'relative', marginLeft: '2%', marginRight: '2%' }}>
                <canvas ref={this.canvas} style={canvasStyle} />
            </div>
        );
    }
}

AudioWaveform.propTypes = ({
    audioData: PropTypes.object.isRequired,
})



class AudioAnalyser extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            audioData: new Uint8Array(0),
         };
        this.source = null;
        this.micTimeout = null;
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.initializeAnalyser(this.props.audio);
    }


    componentWillUnmount() {
        this.cleanup();
    }

    initializeAnalyser(audioStream) {
        if (!audioStream) return;
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.source = this.audioContext.createMediaStreamSource(audioStream);
        this.source.connect(this.analyser);
        this.rafId = requestAnimationFrame(this.tick);

        if (this.props.audio) this.micTimeout = setTimeout(() => {
            ensure_media_stream(this.props.audio).then((hasAudio) => {
                this.props.raiseAudioWarning(hasAudio);
            })
        }, 500); // 3 seconds timeout
    }

    cleanup() {
        if (this.rafId) cancelAnimationFrame(this.rafId);
        if (this.analyser) this.analyser.disconnect();
        if (this.source) this.source.disconnect();
        if (this.audioContext) this.audioContext.close(); // Close the AudioContext to free resources
        if (this.micTimeout) clearTimeout(this.micTimeout);
    }

    tick() {
        if (this.analyser) {
            this.analyser.getByteTimeDomainData(this.dataArray);
            this.setState({ audioData: this.dataArray });
        }
        this.rafId = requestAnimationFrame(this.tick);
    }

    render() {
        return <AudioWaveform audioData={this.state.audioData} />;
    }
}

AudioAnalyser.propTypes = ({
    audio: PropTypes.oneOfType([ PropTypes.object, PropTypes.oneOf([null, undefined]) ]),
    raiseAudioWarning: PropTypes.func,
})

export default AudioAnalyser;