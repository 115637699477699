export function convertTimeString(timeString) {
    const localTimeStamp =  new Date(timeString + 'Z');

    // Convert to AM/PM
    let hoursTime, ampm;
    if (localTimeStamp.getHours() > 12) {
        hoursTime = `${localTimeStamp.getHours() - 12}`
        ampm = 'PM'
    } else if (localTimeStamp.getHours() === 12) {
        hoursTime = `${localTimeStamp.getHours()}`
        ampm = 'PM'
    } else {
        hoursTime = `${localTimeStamp.getHours()}`
        ampm = 'AM'
    }
    // Format given time string to readable date Object
    return `${localTimeStamp.getMonth() + 1}/${localTimeStamp.getDate()}/${localTimeStamp.getFullYear()}, ${hoursTime}:${String(localTimeStamp.getMinutes()).padStart(2, "0")} ${ampm}`
}

export function grabLocalTime(timeString) {
    const localTimeStamp =  new Date(timeString + 'Z');

    return localTimeStamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}


export function calculateAndConvertTime(interval) {
    const currentTime = new Date();

    switch(interval) {
        case ('current'): {
            const currentUTCMonthFormatted = String(currentTime.getUTCMonth() + 1).padStart(2, '0')
            const currentUTCDayFormatted = String(currentTime.getUTCDate()).padStart(2, '0')
            const currentUTCHoursFormatted = String(currentTime.getUTCHours()).padStart(2, '0')
            const currentUTCMinutesFormatted = String(currentTime.getUTCMinutes()).padStart(2, '0')
            const currentUTCSecondsFormatted = String(currentTime.getUTCSeconds()).padStart(2, '0')
            return `${currentTime.getUTCFullYear()}-${currentUTCMonthFormatted}-${currentUTCDayFormatted}T${currentUTCHoursFormatted}:${currentUTCMinutesFormatted}:${currentUTCSecondsFormatted}`
        } case ('24 hours ago'): {
            // Support retrieval of notes from the last 7 days
            const pastTime = new Date(currentTime.getTime() - (24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('last 7 days'): {
            // Support retrieval of notes from the last 7 days
            const pastTime = new Date(currentTime.getTime() - (7 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case('1 month'): {
            // Support retrieval of notes from the last month
            const pastTime = new Date(currentTime.getTime() - (30 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('3 months'): {
            const pastTime = new Date(currentTime.getTime() - (30* 3 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } case ('1 year'): {
            const pastTime = new Date(currentTime.getTime() - (30* 12 * 24 * 60 * 60 * 1000))
            const pastUTCMonthFormatted = String(pastTime.getUTCMonth() + 1).padStart(2, '0')
            const pastUTCDayFormatted = String(pastTime.getUTCDate()).padStart(2, '0')
            const pastUTCHoursFormatted = String(pastTime.getUTCHours()).padStart(2, '0')
            const pastUTCMinutesFormatted = String(pastTime.getUTCMinutes()).padStart(2, '0')
            const pastUTCSecondsFormatted = String(pastTime.getUTCSeconds()).padStart(2, '0')

            return `${pastTime.getUTCFullYear()}-${pastUTCMonthFormatted}-${pastUTCDayFormatted}T${pastUTCHoursFormatted}:${pastUTCMinutesFormatted}:${pastUTCSecondsFormatted}`;
        } default: {
            return 'Implement it later'
        }
    }
}

