import { axiosInstance } from "./Axios";

export async function post_visit(presigned_fields, title, note_type) {
    try {
        const response = await axiosInstance.post('/api/v1/visits', {
            'file_location': presigned_fields['fields']['key'],
            'title': title,
            "note_type": note_type,
        })

        return response.data;
    } catch (error) {
        throw new Error("Unable to create visit");
    }
}

export async function get_visit(visit_id) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visit_id}`);
        return response.data
    } catch(error) {
        throw new Error("Unable to get visit")
    }
}

export async function put_visit(visit_id, request_body) {
    try {
        const response = await axiosInstance.put(`/api/v1/visits/${visit_id}`, request_body);
        return response.data
    } catch(error) {
        throw new Error(error)
    }
}

export async function delete_visit(visit_id) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visits/${visit_id}`)
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}