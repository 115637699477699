import React from 'react';
import PDFObject from "pdfobject";
import { PDFDocument } from 'pdf-lib';
import { PDFFormat } from '../../utils/constants/PDF';
import PropTypes from "prop-types";


// Grab string wrapped in {}
function clean_json(content) {
  const clean_string = content.replace(/\n/g, '');
  const match = clean_string.match(/{.*?}/);
  try {
    return JSON.parse(match[0]);
  } catch (error) {
    throw new Error(error);
  }
}

export async function build_pdf(note) {
    // This grabs it from assets? Should store this in a bucket or something 
    try {
        const generated_fields = clean_json(note.generated_result.value);
        const pdf_bytes = await fetch(`/assets/${note.note_type}.pdf`).then((res) => res.arrayBuffer());
        const pdf_doc = await PDFDocument.load(pdf_bytes);
        const pdf_form = pdf_doc.getForm();

        // Iterate through and set fields
        const pdf_fields = pdf_form.getFields();
        for (const field of pdf_fields) {
          const field_name = field.getName();
          if (field_name in generated_fields && field_name in PDFFormat[note.note_type]) {
            console.log(`Found match ${field_name}`);
            try {
              // For some reason the constructor.names are different in prod?
              if (PDFFormat[note.note_type][field_name] === "TextField") {
                console.log("Filling");
                field.setText(generated_fields[field_name]);
              } else if (PDFFormat[note.note_type][field_name] === "RadioGroup") {
                console.log("Filling");
                field.select(generated_fields[field_name]);
              }
            } catch(error) {
              console.log(error)
            }
          }
        }

        const modified_pdf_bytes = await pdf_doc.save();
        const pdf_blob = new Blob([modified_pdf_bytes], { type: 'application/pdf' });

        // Step 6: Render the new PDF using PDFObject
        const modified_pdf_url = URL.createObjectURL(pdf_blob);
        return modified_pdf_url;
    } catch (error) {
        throw new Error(error);
    }
}   

export const DocEmbedder = ({ note }) => {
    const getURL = async () => {
      const url = await build_pdf(note);
      PDFObject.embed(url, "#pdf-viewer");
    }
  
    // Embed the PDF when the component mounts
    React.useEffect(() => {
      getURL()
    }, []);
  
    return (
      <div>
        <div id="pdf-viewer" style={{ width: "100%", height: "600px" }}></div>
      </div>
    );
  };

DocEmbedder.propTypes = {
  note: PropTypes.shape({
    note_type: PropTypes.string.isRequired,
    generated_result: PropTypes.object.isRequired,
  })
}