import React, { useContext, useEffect, useState } from "react";
import { Typography, Tooltip, Box, Select, Option, Button } from "@mui/joy";
import { AlertContext } from "../context/AlertFlag.js";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { convertTimeString } from "../utils/TimeConversion.js";
import NavBlocker from "../components/modals/NavBlocker.js";
import { NoteTypeSelect } from "../components/custom/NoteTypeSelect.js";
import { useFetchUserPreferences } from "../hooks/api/UserPreferences.js";
import { put_user_preferences } from "../services/UserPreferences.js";

function SettingsView() {
    const { addAlert } = useContext(AlertContext);
    const { preferences, reload } = useFetchUserPreferences();
    const [ splitHPI, setSplitHPI ] = useState(true);
    const [ noteFormat, setNoteFormat] = useState('');
    const [ noteType, setNoteType ] = useState('');
    const [ noteViews, setNoteViews ] = useState([]);
    const [ updatedAt, setUpdatedAt] = useState('');
    const [ voiceLanguage, setVoiceLanguage ] = useState('en-US');
    const [ noteLanguage, setNoteLanguage ] = useState('en-US');

    const [ dirty, setDirty] = useState(false);

    const handleNoteType = (event, newValue) => {
        setNoteType(newValue);
    }

    const handleVoiceLanguage = (event, voiceInput) => {
        setVoiceLanguage(voiceInput);
    }

    const handleNoteLanguage = (event, noteLanguage) => {
        setNoteLanguage(noteLanguage);
    }

    const handleNoteFormat = (event, newValue) => {
        setNoteFormat(newValue);
    }

    const handleNoteViews = (event, newViews) => {
        setNoteViews(newViews)
    }

    useEffect(() => {
        if (preferences) {
            setSplitHPI(preferences['split_hpi']);
            setNoteFormat(preferences['note_format']);
            setNoteType(preferences['note_type']);
            setNoteViews(preferences['note_views']);
            setVoiceLanguage(preferences['input_language']);
            setNoteLanguage(preferences['output_language']);
            setUpdatedAt(convertTimeString(preferences['updated_at']));
        }
    }, [preferences])

    useEffect(() => {
        if (preferences && (splitHPI !== preferences['split_hpi'] || noteFormat !== preferences['note_format'] || noteType !== preferences['note_type'] ||  voiceLanguage !== preferences['input_language'] || noteLanguage !== preferences['output_language'])) { 
            setDirty(true);
        } else {
            setDirty(false);
        }
    }, [noteFormat, splitHPI, noteType, voiceLanguage, noteLanguage, noteType, preferences]);

    const handleSavePreferences = async () => {
        if (noteViews.length <= 0) {
            addAlert("You need to select at least one note view!", "danger");
            return;
        }
        if (!noteType) {
            addAlert("You need to select a default note type!", "danger");
            return;
        }
        try {
            await put_user_preferences(splitHPI, noteFormat, noteType, noteViews, voiceLanguage, noteLanguage);
            reload();
            addAlert("Saved!", "success");
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }
    

    return (
        <Box id="outline-it" sx={{
            display: "flex", flexDirection: "column", gap: 2, border: "1px solid #ccc"
        }}>
            <NavBlocker dirty={dirty} />
            <Typography level='h2'>Settings</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', lg: 'row'},
                    justifyContent: 'space-between',
                    flexWrap: "wrap",
                    gap: 2,
                    px: "1rem"
                }}
            >
                <NoteTypeSelect value={noteType}
                            disabled={false}
                            onChange={handleNoteType}
                            sx={{
                                width: {sm: "50%", md: "100%"},
                                ml: 2
                            }}
                            calmTitle={true}
                            noteViews={noteViews}
                            />
                <Box sx={{alignItems: 'center', gap: 2, alignContent: "center"}}>
                    <Box
                        sx={{display: 'flex', alignItems: 'center', gap: 2, pb: '1rem'}}
                    >
                        <Typography>Note Views:</Typography>
                        <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem", flexWrap: "wrap"}}>
                            <Select
                                value={noteViews}
                                multiple
                                onChange={handleNoteViews}
                                sx={{
                                    width: {sm: "50%", md: "80%"},
                                    maxWidth: "300px",
                                }}
                            >
                                <Option value="pediatric">Pediatric</Option>
                                <Option value="therapy">Therapy</Option>
                                <Option value="radiology">Radiology</Option>
                                <Option value="emergency_response">Emergency Response</Option>
                                <Option value="rehab">Rehab</Option>
                                <Option value="sports_medicine">Sports Medicine</Option>
                                <Option value="physio">Physio</Option>
                                <Option value="general_notes">General Notes</Option>
                                <Option value="athletic">Athletic</Option>
                                <Option value="pedorthist">Pedorthist</Option>
                                <Option value="geriatric">Geriatric</Option>
                            </Select>
                            <Tooltip title={'Note Views allows you to configure which note types you use in your practice'}> 
                                <HelpOutlineIcon/> 
                            </Tooltip>
                        </Box>
                    </Box>
                    {/* <Box
                        sx={{display: 'flex', alignItems: 'center', gap: 2}}
                    >
                        <Typography width="30%">Note Format:</Typography>
                        <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                            <Select
                                value={noteFormat}
                                onChange={handleNoteFormat}
                                sx={{
                                    width: {
                                        xs: "100%", 
                                        sm: "80%",  
                                        md: "60%",  
                                        lg: "50%",  
                                      },
                                }}
                            >
                                <Option value="default">Default</Option>
                                <Option value="paragraph">Paragraph</Option>
                                <Option value="bullet_points">Bullet Points</Option>
                            </Select>
                            <Tooltip title={'Note Format determines the formatting of the structured note.'}> 
                                <HelpOutlineIcon/> 
                            </Tooltip>
                        </Box>
                    </Box> */}
                </Box>
                <Box
                    sx={{alignItems: 'center', gap: 2,  justifyContent: 'space-between'}}
                >
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem", pb: '1rem'}}>
                        <Typography>Voice Language:</Typography>
                        <Select value={voiceLanguage} onChange={handleVoiceLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="zh-CN"> Chinese(Mandarin, Simplified) </Option>
                            <Option value="zh-TW"> Chinese(Mandarin, Traditional) </Option>
                            <Option value="zh-HK"> Chinese(Cantonese, Simplified) </Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                        <Typography pr={'5px'}>Note Language: </Typography>
                        <Select value={noteLanguage} onChange={handleNoteLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="zh-CN"> Chinese(Mandarin, Simplified) </Option>
                            <Option value="zh-TW"> Chinese(Mandarin, Traditional) </Option>
                            <Option value="zh-HK"> Chinese(Cantonese, Simplified) </Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}>
                    <Typography>
                        Last updated at: {updatedAt}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    gap: 2,
                    px: "1rem"
                }}
            >
                
                <Box sx={{ display: 'flex', width: '100%', justifyContent: { xs: 'center', md: 'start' }, }}>
                    <Button onClick={handleSavePreferences} size='lg' sx={{width: '10rem',}}>Save</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SettingsView;