import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { User, NotepadText, MessagesSquare, Timer, Speech, RotateCcw, Plus, Send } from 'lucide-react';

import React, { useContext, useEffect, useState, useRef, useCallback } from "react"
import { useParams, useOutletContext, useNavigate, useSearchParams } from "react-router-dom"
import { useFetchVisit } from "../hooks/api/Visits";
import { CircularProgress, Typography, Sheet, Box, Button, Divider, Input, Card, CardContent, IconButton } from "@mui/joy";
import { AlertContext } from "../context/AlertFlag";
import { convertTimeString } from '../utils/TimeConversion';
import { delete_visit, put_visit } from "../services/VisitRouter";
import useDebounce from '../hooks/useDebounce';
import NavBlocker from '../components/modals/NavBlocker';
import { NoteTextComponent } from '../components/custom/NoteTextComponent';
import ConfirmDeleteVisitModal from '../components/modals/ConfirmDeleteVisit';
import ConfirmEmailModal from '../components/modals/ConfirmSendEmail';
import { sendEmail } from '../services/Email';
import { formatNoteName, formatNoteSections, NoteNameIcon } from '../utils/FormatNoteNameAndText';
import { NoteSections } from '../utils/constants/Notes';
import { RegenerateNoteTypeModal } from '../components/modals/RegenerateNote';
import { AddNoteModal } from '../components/modals/AddNoteModal';
import { delete_generate_note, put_generate_note, regenerate_note } from '../services/GenerateNote';

function PastVisitsExpanded() {
    const { visitId } = useParams();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const generate_note_id = searchParams.get("generate_note_id");
    const { addAlert } = useContext(AlertContext);
    const parentContext = useOutletContext();
    const navigate = useNavigate();
    const { visit, visitLoading, visitError, reload } = useFetchVisit(visitId);

    const [ note, setNote ] = useState(null);
    const [ triggerReload, setTriggerReload ] = useState(0);
    const [ title, setTitle ] = useState(null);
    const [ editingNote, setEditingNote ] = useState(false);
    const [ editingTranscript, setEditingTranscript ] = useState(false);
    const [ view, setView ] = useState("note");
    const [ deleteVisit, setDeleteVisit ] = useState(false);

    const [ regenModal, setRegenModal ] = useState(false);
    const [ addNoteModal, setAddNoteModal ] = useState(false);
    const [ openConfirmSendEmail, setOpenConfirmSendEmail ] = useState(false);

    const noteRef = useRef(null);
    const transcriptRef = useRef(null);

    const chooseTranscript = () => {
        if (visit['user_edited_transcript']['value'].length <= 2 && visit['transcription'] && visit['transcription']['result']) {
            return visit['transcription']['result']['transcript']
        } else {
            return visit['user_edited_transcript']['value']
        }
    }

    const handleCancelTranscript = () => {
        setEditingTranscript(false);

        if (transcriptRef.current) {
            transcriptRef.current.textContent = chooseTranscript()
        }
    }

    const handleSaveNote = async (text, note_id) => {
        try {
            await put_generate_note(visitId, note_id, text);
            addAlert("Note saved!", "success");
            reload();
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleRegenerateNote = async (note_id) => {
        try {
            const transcript_choice = visit.user_edited_transcript.value.length > 0 ? 'user_edit' : 'deepgram';
            await regenerate_note(visitId, note_id, transcript_choice)
            addAlert("Regeneration triggered!", "success");
            reload();
        } catch(error) {
            addAlert(error.message, "danger");
            throw new Error(error);
        }
    }

    const handleDelete = async () => {
        try {
            await delete_visit(visitId);
            setTriggerReload((prev) => prev + 1);
            addAlert("Visit deleted!", "success");
            navigate('/past-visits');
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleDeleteNote = async (note_id) => {
        try {
            if (visit.generate_notes.length <= 1) {
                addAlert("You must have at least one note per visit!", "danger");
                return;
            }
            await delete_generate_note(visitId, note_id);
            addAlert("Note deleted!", "success");
            setTriggerReload((prev) => prev + 1);
            searchParams.delete('generate_note_id');
            setSearchParams(searchParams);
        } catch (error) {
            throw new Error(error);
        }
    }

    const handleSaveTranscript = async () => {
        setEditingTranscript(false);
        try {
            const request_body = {
                "title": title,
                "user_edited_transcript": {
                    "value": transcriptRef.current.innerText.trim(),
                }
            }
            await put_visit(visitId, request_body);
            addAlert("Saved!", "success");
            const note_types = [];
            for (let note of visit.generate_notes) {
                note_types.push(formatNoteName(note.note_type));
            }
            addAlert(`The following notes captured context from the previous transcript: ${note_types}`, "warning")
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleTitleChange = useCallback(async () => {
        if (title && visit) {
            const request_body = {
                  "title": title,
                  "user_edited_transcript": {
                      "value": visit.user_edited_transcript.value,
                  }
            }
            try {
                await put_visit(visitId, request_body);
            } catch (error) {
                addAlert(error.message, "danger")
            }
        }
    }, [ title ])
    useDebounce(handleTitleChange, 500, [title])

    const handleChangeNote = (note) => {
        setSearchParams({ generate_note_id: note.id })
        setNote(note);
    }

    useEffect(() => {
        parentContext.reload();
        reload();
    }, [triggerReload, put_visit])

    useEffect(() => {
        if (visitError) {
            addAlert(visitError.message, "danger");
        }
    }, [visitError])

    useEffect(() => {
        if (visit && visit.title) {
            setTitle(visit.title);
        } 

        // If none set, set view to first visit
        if (visit && visit.generate_notes.length > 0) {
            if (!generate_note_id) {
                handleChangeNote(visit.generate_notes[0]);
            } else {
                for (let note_index in visit.generate_notes) {
                    if (generate_note_id === visit.generate_notes[note_index].id) {
                        handleChangeNote(visit.generate_notes[note_index])
                        break;
                    }
                }
            }
        }
    }, [visit])

    return <Sheet sx={{width: "100%", height: "100vh", overflowY: "auto", overflowX: "hidden"}} key={visitId}>
        <NavBlocker dirty={editingNote || editingTranscript}/>
        {deleteVisit && <ConfirmDeleteVisitModal open={deleteVisit} on_close={() => {setDeleteVisit(false)}} delete_visit={handleDelete}/>}
        {openConfirmSendEmail && <ConfirmEmailModal
                    open={openConfirmSendEmail}
                    closePopupExternal={() => {
                        setOpenConfirmSendEmail(false);
                    }}
                    sendEmailExternal={async () => {
                        await sendEmail(visit.id);
                        addAlert("Sent email!", "success");
                    }}
                />}
        {visit && <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", backgroundColor: "white", pl: 2, pr: 4, paddingTop: 3.75 }}>
                <Input value={title} size="lg" variant="plain" onChange={(event) => {
                    setTitle(event.target.value)
                }} startDecorator={<User />} sx={{ fontSize: "larger", fontWeight: "600", backgroundColor: "white", width: "50%" }}/>
                <Box sx={{display: "flex", gap: 2 }}>
                    {/* <Button sx={{ display: "flex", width: "fit-content", whiteSpace: "nowrap" }} startDecorator={<CloudOutlinedIcon />}>
                        Sync to EMR
                    </Button> */}
                    <Button onClick={async () => {setDeleteVisit(true)}} sx={{ display: "flex", backgroundColor: "white", width: "fit-content" }} startDecorator={<DeleteOutlineIcon/>} variant="outlined" color="danger"> Delete </Button>
                </Box>
            </Box>
            <Box sx={{display: "flex", justifyContent: "space-between", backgroundColor: "white", px: 4, py: 1, flexWrap: "wrap"}}>
                <Box sx={{display: "flex", gap: 1.7, alignItems: "center"}}>
                    <Timer style={{ color: "var(--joy-palette-text-tertiary, var(--joy-palette-neutral-600, #555E68))"}}/>
                    <Typography level="body-sm"> Created at: {convertTimeString(visit.created_at)}</Typography>
                    <Typography level="body-sm"> Updated at: {convertTimeString(visit.updated_at)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Button sx={{ backgroundColor: "white", width: "fit-content", display: "flex" }} variant="plain" onClick={() => {
                            if (view === "note") {
                                setView("transcript");
                            } else {
                                setView("note");
                            }
                        }} startDecorator={view === "note" ? <Speech /> : <NotepadText />}> {view === "note" ? "Transcript" : "Note"} </Button>
                    <IconButton onClick={() => { setOpenConfirmSendEmail(true) }}>
                        <Send />
                    </IconButton>
                </Box>
            </Box>
            <Divider/>
            {view === "note" && 
            <>
                <Box sx={{ px: 2, py: 2, display: "flex", gap: 1, width: { xl: "60vw", lg: "50vw", md: "45vw"}, minWidth: "300px", overflowX: "auto" }}>
                        {visit.generate_notes.map((item, index) => {
                            return <Card key={item.id} sx={{ 
                                    backgroundColor: generate_note_id === item.id ? "var(--joy-palette-primary-softBg)" : "white", 
                                    width: "fit-content",  
                                    border: "1px solid var(--joy-palette-divider)",
                                    transition: "all 0.2s ease-in-out",
                                    cursor: generate_note_id === item.id ? "not-allowed" : "pointer",
                                    ...(generate_note_id === item.id ? {} : {"&:hover": {
                                        backgroundColor: "var(--joy-palette-primary-softBg)", // Light hover effect
                                        borderColor: "var(--joy-palette-primary-outlinedBorder)", // Change border on hover
                                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                    },
                                    "&:active": {
                                        transform: "scale(0.98)", // Button press effect
                                    }})
                                }}
                                disabled={!item.generated_result || !item.generated_result.value}
                                onClick={() => { handleChangeNote(visit['generate_notes'][index]) }}
                            >
                                <CardContent>
                                    <Box sx={{ display: "flex", gap: 1.7, alignItems: "center"}}>
                                        {item.generated_result && item.generated_result.value ? <NoteNameIcon note_type={item.note_type} size={"30px"}/> : <CircularProgress />}
                                        <Box>
                                            <Typography level="title-sm" sx={{ whiteSpace: "nowrap" }}>
                                                {formatNoteName(item.note_type)}
                                            </Typography>
                                            <Typography level="body-xs" sx={{ fontStyle: "italic", color: "gray" }}>
                                                {formatNoteSections(NoteSections.using_note_type(item.note_type).join())}
                                            </Typography>
                                        </Box>
                                        <IconButton sx={{ width: "fit-content" }} disabled={!item.generated_result || !item.generated_result.value} onClick={() => { setRegenModal(true) }}>
                                            <RotateCcw style={{ width: "17.5px" }} />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        })}
                        <Card key={"add-note"} sx={{ 
                            backgroundColor: "white", 
                            width: "fit-content",  
                            border: "1px dashed var(--joy-palette-divider)",         
                            transition: "all 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "var(--joy-palette-primary-softBg)", // Light hover effect
                                borderColor: "var(--joy-palette-primary-outlinedBorder)", // Change border on hover
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                            },
                            "&:active": {
                                transform: "scale(0.98)", // Button press effect
                            } 
                        }} 
                        onClick={() => { 
                            setAddNoteModal(true)
                         }}>
                            <CardContent sx={{ justifyContent: "center" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Plus />
                                    <Typography level='body-xs' sx={{ whiteSpace: "nowrap" }}>
                                        Add Note
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        {addNoteModal && <AddNoteModal open={addNoteModal} closePopup={() => { setAddNoteModal(false) }} visit_id={visitId} reload={() => { setTriggerReload((prev) => prev + 1) }}/>}
                        {regenModal && <RegenerateNoteTypeModal open={regenModal} visit={visit} closePopup={() => { setRegenModal(false) }} regenerate={
                            async () => {
                                await handleRegenerateNote(note.id);
                            }}/>}
                    </Box>
                <Divider />
            </>}
            <Box sx={{ backgroundColor: "var(--joy-palette-neutral-50)" }}>
                <Box sx={{ m: 2, py: 2, border: "1px solid var(--joy-palette-divider)", backgroundColor: "white", borderRadius: "12px" }}>
                    <Box sx={{ display: view === "note" ? "block" : "none" }}>
                        {visit.generate_notes.length > 0 && note && note.generated_result && note.generated_result.value ? 
                            <NoteTextComponent ref={noteRef} visit={visit} note={note} lift_editing={(value) => {setEditingNote(value)}} lift_save={handleSaveNote} lift_delete={handleDeleteNote}/>
                            :
                            <Box sx={{ display: "flex", height: "50vh", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                <Typography level='body-lg'>
                                    Capturing visit context... Please refresh the page to check the updated status!
                                </Typography>
                                <CircularProgress size="lg"/>
                            </Box>
                    }
                    </Box>
                    <Box sx={{ display: view === "transcript" ? "block" : "none"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", py: 0.5, px: 2, gap: 1}}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <MessagesSquare style={{ color: "var(--main-blue)", height: "2.75em", width: "2.75em", paddingRight: 2}}/>
                                <Typography level="title-lg" sx={{pb: 0.4}}> Transcript </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                {editingTranscript && <Button sx={{ backgroundColor: "white", color: "gray" }} variant="plain" onClick={() => {
                                    handleCancelTranscript();
                                    setEditingTranscript(false);
                                }}>
                                    Cancel
                                </Button>}
                                <Button sx={{ backgroundColor: "white" }} variant="plain" onClick={ async () => {
                                    if (editingTranscript) {
                                        await handleSaveTranscript();
                                    } else {
                                        setEditingTranscript(true);
                                    }
                                }}>
                                    {editingTranscript ? "Save" : "Edit"}
                                </Button>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 1 }}/>
                        <Box sx={{whiteSpace: 'pre-wrap', p: 1, }}>
                            <Typography level="body-sm" sx={{ mt: 2, px: 2 }} ref={transcriptRef} contentEditable={editingTranscript} suppressContentEditableWarning={true}>
                                {chooseTranscript()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>}
        {visitLoading && <Box sx={{ display: "flex", height: "70vh", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size="lg"/>
        </Box>}
    </Sheet>

}

export default PastVisitsExpanded;