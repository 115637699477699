import { axiosInstance } from "../services/Axios";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

export const broadcastEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
};

export const getToken = () => {
    return localStorage.getItem('token');
}

export const getUserID = () => {
    return localStorage.getItem('user_id');
}

export const getFullName = () => {
    return localStorage.getItem('user_full_name');
}

export const clearUser = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('user_id', null);
    localStorage.setItem('user_full_name', null);
    broadcastEvent('localStorageChange', { key: 'token', value: null });
}


export async function authGuard () {
    const token = getToken();
    const userID = getUserID();
    const userFullName = getFullName();

    let status;
    if (token === "null" || userID === "null") {
        return false;
    }
    await axiosInstance.get(`/api/v1/user/${userID}`).then( response => {
        if (userFullName !== response.data.full_name) {
            localStorage.setItem('user_full_name', response.data.full_name);
        }
        status = response.status;
    }).catch( error => {
        status = error.status;
    })

    if (status === 200) {
        console.log("Valid Token");
        return true;
    } else {
        console.log("Blocked");
        return false;
    }
}

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const [ authState, setAuthState ] = useState(null);

    useEffect(() => {
        const checkAuthState = async () => {
            setAuthState(await authGuard())
        }
        checkAuthState();
    }, [location])

    if (authState === null) {
        return <div> Loading... </div>
    }
    
    if (!authState) {
        return <Navigate to='/login' state={{from: location}} replace/>
    }

    return children
}

ProtectedRoute.propTypes = {
    children: PropTypes.element.isRequired,
}

export default ProtectedRoute