import React, { useEffect, useState, useContext } from "react";
import MicrophoneContext from "../../../context/MicContext";
import { Select, Option, Box, Typography } from "@mui/joy";
import { Mic } from "@mui/icons-material";
import PropTypes from "prop-types";

const MicrophoneSelect = ({ updateRecordingStream, disabled }) => {
  const { selectedMic, setSelectedMic } = useContext(MicrophoneContext);
  const [microphones, setMicrophones] = useState([]);
  const [permissionsGranted, setPermissionsGranted] = useState(false);

  const requestMicPermissions = async () => {
    try {
      // Request permission explicitly
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setPermissionsGranted(true);
    } catch (error) {
      setPermissionsGranted(false);
    }
  };

  const fetchMicrophones = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter((device) => device.kind === "audioinput");

      setMicrophones(mics);

      if (mics.length > 0) {
        setSelectedMic(mics[0].deviceId);
      } else {
        console.warn("No microphones detected");
      }
    } catch (error) {
      console.error("Error fetching microphones:", error);
    }
  };

  // Request permissions on mount
  useEffect(() => {
    requestMicPermissions();
  }, []);

  // Fetch microphones only after permission is granted
  useEffect(() => {
    if (permissionsGranted) {
      fetchMicrophones();
    }
  }, [permissionsGranted]);

  // Update the recording stream when the selected mic changes
  useEffect(() => {
    if (selectedMic) {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: { exact: selectedMic } } })
        .then((stream) => {
          updateRecordingStream(stream);
        })
        .catch(() => {
          fetchMicrophones(); // Retry fetching devices in case of failure
        });
    }
  }, [selectedMic]);

  const handleChange = (e, mic) => {
    setSelectedMic(mic);
  };

  return (
    <Box sx={{ width: { xs: "100%", sm: "25%" } }}>
      <Typography level="body-sm"> Audio Source </Typography>
      <Select
        value={selectedMic}
        onChange={handleChange}
        disabled={disabled || !permissionsGranted} // Disable if permissions not granted
        startDecorator={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Mic />
          </Box>
        }
      >
        <Option value="" disabled>
          {permissionsGranted ? "Select a microphone" : "Waiting for permission..."}
        </Option>
        {microphones.map((mic) => (
          <Option key={mic.deviceId} value={mic.deviceId}>
            {mic.label || `Microphone ${mic.deviceId}`}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

MicrophoneSelect.propTypes = {
  updateRecordingStream: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default MicrophoneSelect;
