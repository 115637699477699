// redux/counterSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get_visit } from "../../services/VisitRouter";

// Lift up the waiting part for the note to happen here -> store in an array
// Add a reducer to fire "check state" of notes -> For each note_id, fire "check state"
// If a note has been completed through "check state", remove and send alert


export const get_visit_generate_by_id = createAsyncThunk('visits/getVisitByID', async (visit_id, { dispatch }) => {
    try {
        // Should only check newly created visits transcription
        const response = await get_visit(visit_id);
        console.log(`Getting ${visit_id}`);
        if (response.transcription !== null && response.generate_notes.length > 0 && response.generate_notes[0].generated_result.value) {
            console.log("Visit finished");
            dispatch(removeGenVisit(response));
        }

        return response.data;
    } catch (error) {
        throw new Error(error);
    }
})

const visitSlice = createSlice({
  name: "counter",
  initialState: {
    generating_visits: [],
    state: "empty"
  },
  reducers: {
    addGenVisit: (state, action) => {
        state.generating_visits.push(action.payload);
        if (state.state === "empty") {
            state.state = "needs_check"
        }
    },
    removeGenVisit: (state, action) => {
        const remove_index = state.generating_visits.findIndex((visit) => visit.id === action.payload.id)
        state.generating_visits.splice(remove_index, 1);
        if (state.generating_visits.length === 0 && state.state === "needs_check") {
            state.state = "empty"
        }
    },
  },
});



export const { addGenVisit, removeGenVisit } = visitSlice.actions;
export default visitSlice.reducer;
