import React, { useEffect, useState } from "react";
import { Box, Select, List, ListItem, Typography, Option } from "@mui/joy";

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { capitalizeWords, formatNoteName } from "../../utils/FormatNoteNameAndText";
import { NoteSections } from "../../utils/constants/Notes";
import PropTypes from "prop-types";


export function NoteTypeSelect({onChange, value, disabled, sx, calmTitle, noteViews, ...rest}) {
    const [ sections, setSections ] = useState(null);

    useEffect(() => {
        setSections(noteViews);
    }, [noteViews])

    return <Box sx={{display: "flex", alignItems: "center"}}>
        {!disabled && calmTitle && <h3>Note Type: </h3>}
        {calmTitle && disabled && <Typography> Note Type: </Typography>}
        <Select
        placeholder="Note Type"
        name="note_select"
        startDecorator={<AppRegistrationIcon />}
        onChange={onChange}
        value={value}
        disabled={disabled}
        sx={sx}
        {...rest}
        >
        {Object.entries(NoteSections).map(([section, notes]) => (
            <React.Fragment key={section}>
            {/* You can still show a header if needed */}
            <Typography
                level="body-xs"
                sticky
                sx={{ textTransform: "uppercase", pointerEvents: "none", px: 1.5, py: 1}}
            >
                {capitalizeWords(section, "_")}
            </Typography>
            {notes.map((note) => {
                return <Option key={note} value={note}>
                    {formatNoteName(note)}
                </Option>
            })}
            </React.Fragment>
        ))}

        {/* Additional groups like PDF Forms */}
        {/* <React.Fragment key="pdf_forms">
            <Typography
            level="body-xs"
            sx={{ textTransform: "uppercase", pointerEvents: "none", px: 2 }}
            >
            PDF Forms
            </Typography>
            {NoteSections.pdf_forms.map((note) => (
            <Option key={note} value={note}>
                {formatNoteName(note)}
            </Option>
            ))}
        </React.Fragment> */}

        {/* And Beta */}
        {/* <React.Fragment key="beta">
            <Typography
            level="body-xs"
            sx={{ textTransform: "uppercase", pointerEvents: "none", px: 2 }}
            >
            BETA
            </Typography>
            {NoteSections.beta.map((note) => (
            <Option key={note} value={note}>
                {formatNoteName(note)}
            </Option>
            ))}
        </React.Fragment> */}
        </Select>
    </Box>
}

NoteTypeSelect.propTypes = ({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    calmTitle: PropTypes.bool,
    noteViews: PropTypes.arrayOf(PropTypes.string),
})

NoteTypeSelect.defaultProps = {
    value: null,
    disabled: false,
    calmTitle: false,
    noteViews: null,
}