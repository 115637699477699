import React, { createContext, useState } from 'react';
import PropTypes from "prop-types";


const MicrophoneContext = createContext();

export const MicrophoneProvider = ({ children }) => {
  const [selectedMic, setSelectedMic] = useState(null);

  return (
    <MicrophoneContext.Provider value={{ selectedMic, setSelectedMic }}>
      {children}
    </MicrophoneContext.Provider>
  );
};

MicrophoneProvider.propTypes = {
  children: PropTypes.element.isRequired,
}


export default MicrophoneContext;
