import { axiosInstance } from "./Axios";




export async function create_generate_note(visit_id, note_type) {
    try {
        const response = await axiosInstance.post(`/api/v1/visits/${visit_id}/generate_notes`, { 'note_type': note_type })

        return response.data
    } catch (error) {
        throw new Error(error);
    }
}


export async function get_generate_note(visit_id, generate_note_id) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${visit_id}/generate_notes/${generate_note_id}`)

        return response.data
    } catch (error) {
        throw new Error(error);
    }
}

export async function put_generate_note(visit_id, generate_note_id, user_edited_result) {
    try {
        const response = await axiosInstance.put(`/api/v1/visits/${visit_id}/generate_notes/${generate_note_id}`, { 'user_edited_result': { 'value': user_edited_result } })

        return response.data
    } catch (error) {
        throw new Error(error);
    }
}

export async function regenerate_note(visit_id, generate_note_id, transcript_choice) {
    try {
        const response = await axiosInstance.post(`/api/v1/visits/${visit_id}/generate_notes/${generate_note_id}/regenerate`, { 'transcript_source': transcript_choice })

        return response.data
    } catch (error) {
        throw new Error(error);
    }
}

export async function delete_generate_note(visit_id, generate_note_id) {
    try {
        await axiosInstance.delete(`/api/v1/visits/${visit_id}/generate_notes/${generate_note_id}`)
    } catch (error) {
        throw new Error(error);
    }
}